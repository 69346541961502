import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faYoutube,
  faTwitter,
  faTwitch,
  faMastodon,
} from "@fortawesome/free-brands-svg-icons"
import { faRss } from "@fortawesome/free-solid-svg-icons"
import Logo from "../../content/assets/logo_bunner.svg"

const Layout = props => {
  const { children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`# `}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul
              className="nav"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="menu"
            >
              <li
                className={`nav-home`}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="menuitem"
              >
                <Link to={`/`} activeClassName={`nav-current`}>
                  Home
                </Link>
              </li>
              <li
                className="nav-about"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="menuitem"
              >
                <Link to={`/about`} activeClassName={`nav-current`}>
                  About
                </Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              <img
                src={Logo}
                width="300px"
                height="60px"
                className="site-head-logo"
                alt="Unlimish's Logo"
              />
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://youtube.com/c/unlimish"
                title="YouTube"
                target="_blank"
                rel="noopener noreferrer me"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                href="https://twitch.tv/unlimish"
                title="Twitch"
                target="_blank"
                rel="noopener noreferrer me"
              >
                <FontAwesomeIcon icon={faTwitch}/>
              </a>
              <a
                href="https://github.com/unlimish"
                title="GitHub"
                target="_blank"
                rel="noopener noreferrer me"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a
                href="https://twitter.com/unlimish"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer author me"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://mstdn.maud.io/@unlimish"
                title="Mastodon"
                target="_blank"
                rel="noopener noreferrer author me"
              >
                <FontAwesomeIcon icon={faMastodon} />
              </a>
              <Link
                to={`/rss.xml`}
                title="RSS"
                target="_blank"
                rel="noopener noreferrer me"
              >
                <FontAwesomeIcon icon={faRss} />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} Unlimish
      </footer>
    </div>
  )
}

export default Layout
